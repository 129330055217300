import { useContext } from 'react';
import SessionContext from '../../../../library/Core/SessionContext';
import BasicInfoVisual from '../../../../styles/images/basic-info-visual.png';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../../library/Core/SmartFunctions';
import { useNavigate } from 'react-router-dom';

const AlumniBreakLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);
    const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();
    const SchoolLogo = sessionStorage.getItem('school-image') as string;
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;
    const instituteName = getDomainValueForCode(''+state?.data?.selectedInstitute?.instituteId, 'INSTITUTE_DOMAIN', state);
    
    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}

                        {userTypeCode == 'SUPER_ADMIN' && state?.data?.userInstitutes.length > 1 && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <label className="institute">
                                        Currently selected institute - 
                                        <span>{` ${instituteName.charAt(0).toUpperCase() + instituteName.slice(1)}`}</span>
                                        <span><button className={"btn"} onClick={() => navigate(`/alumni/${state?.data?.id}/select-institute`)}>{'Click here to change'}</button></span>
                                    </label>
                                </div>
                            </div>
                        )}

                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="steps_questions">{props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        Hey{' '}
                                        <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}

                        {userTypeCode == 'SUPER_ADMIN' && state?.data?.userInstitutes.length > 1 && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    
                                </div>
                            </div>
                        )}

                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody aos-init aos-animate" data-aos="fade-left">
                                <div className="step step_1">
                                    <a
                                        data-bs-toggle="collapse"
                                        data-bs-target="#listed_points"
                                        href=""
                                        className="mobile-step-block"
                                        aria-expanded="false"></a>
                                    <div className="textDetail">
                                        <h2 className="stepHeader">
                                            BASIC INFORMATION
                                            <br /> GETS YOU ACCESS TO -
                                        </h2>
                                        <div className="listed_points" id="listed_points">
                                            <ul>
                                                <li>Fellow Alumni from the same field, city, and country.</li>
                                                <li>Insights on courses, careers, and current status of your fellow Alumni</li>
                                            </ul>
                                        </div>
                                        <a href="" className="step_header_wrap_link">
                                            Read <span className="more">More</span>
                                            <span className="less">Less</span>
                                        </a>
                                    </div>
                                    <div className="step_img">
                                        <img src={BasicInfoVisual} className="img-fluid float_horizontal_animate" width="100%" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a data-bs-toggle="collapse" data-bs-target="#listed_points" href="" className="bg-drop" aria-expanded="false"></a>
            </div>
        </>
    );
};

export default AlumniBreakLayout;
