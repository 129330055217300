import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import AlumniPostGradCardControl from '../../../card-controls/AlumniPostGradCardControl';
import CarouselComponent from '../../../card-controls/CarouselComponent';
import SessionContext from '../../../../library/Core/SessionContext';
import { getDomainValueForCode, isEmpty, isSchoolAdmin } from '../../../../library/Core/SmartFunctions';

const ProfilePostGraduate = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const model = state.internal.model;
    const navigate = useNavigate();

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    const userType = sessionStorage.getItem('user-type-code');

    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
        setIsHidden(!isHidden);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const owlNextElement = document.getElementsByClassName('owl-next')[0] as HTMLDivElement; // Adjust the type to match the actual element type
            if (owlNextElement) {
                owlNextElement.click();
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    let titleStr: string = 'Other Alumni who pursued <span>Post Graduation</span>';
    const title = { __html: titleStr };
    console.log('model.postGradColleges', model.postGradColleges);

    return (
        <div>
            <div className="tab-pane fade active show" id="pills-postGraduate" role="tabpanel" aria-labelledby="pills-postGraduate-tab">
                <div className="postGraduate-detail">
                    <div className="blockHeader">
                        <div className="row g-2  align-items-center">
                            <div className="col">
                                {!model.isEditable && (
                                    <div className="title">
                                        Post Graduation College(s) of{' '}
                                        <span>
                                            {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                        </span>
                                    </div>
                                )}
                                {model.isEditable && <div className="title">My Post Graduation College(s)</div>}
                            </div>
                            {model.isEditable && (
                                <div className="col-auto">
                                    <a role="button" onClick={() => navigate(`/alumni/${model.id}/pg`)} className="btn-add-section">
                                        <i></i> <span>ADD</span>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    {model.postGradColleges.map((college: any) => (
                        <div key={college.universityId + college.universityName} className="graduateRow edit">
                            <div className="college_detail_wrap">
                                <div className="college_detail">
                                    <div className="college_logo">
                                        <a
                                            className=""
                                            // href={college.websiteUrl} target="_blank"
                                            href="javascript:void(0)"
                                            rel="noopener noreferrer"
                                            style={{ cursor: 'default' }}>
                                            <img
                                                className="img-fluid"
                                                width="80"
                                                height="80"
                                                src={`${college.logoFileName}`}
                                                alt="No Image"
                                            />
                                        </a>
                                    </div>
                                    <div className="college_name_scholarship">
                                        <div className="college_name">
                                            <a
                                                // href={college.websiteUrl}
                                                href="javascript:void(0)"
                                                // target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none', cursor: 'default' }}>
                                                <h3>{college.universityName}</h3>{' '}
                                            </a>
                                            <p>
                                                {college.cityName && `${college.cityName},`} {college.stateName && `${college.stateName},`}{' '}
                                                {college.countryName} | Estd.
                                                {college.establishedYear}
                                            </p>
                                            <div className="alumni_profile_college_subject">
                                                <i className="icon"></i>
                                                {/* <span>{college.fieldOfStudy}</span> */}
                                                {college.fieldOfStudy === '1' ? (
                                                    <span>
                                                        {getDomainValueForCode(
                                                            college.fieldOfStudy,
                                                            'FIELD_OF_STUDY_TYPE_CODE_ALUMNI',
                                                            state
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span>{college.fieldOfStudy}</span>
                                                )}
                                            </div>
                                        </div>
                                        {!isEmpty(college?.scholarship) ? (
                                            <div className="scholarship">
                                                <span className="scholarship_label">Scholarship</span>
                                                <span className="scholarship_amt">{college.scholarship}</span>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="offer offer_edit">
                                <div className="offer_existing_links">
                                    {college.recordTypeCode === 'JOINED' && (
                                        <a className="joindeLink">
                                            <i className="icon-like"></i> <span>{college.recordTypeCode}</span>
                                        </a>
                                    )}
                                    {college.collegeApplicationResultCode === 'GOT_OFFER_DID_NOT_JOIN' && (
                                        <div className="got_offer">
                                            <i className="icon-like"></i>
                                            <span>Got Offer but did not join</span>
                                        </div>
                                    )}
                                    {college.collegeApplicationResultCode === 'NO_OFFER' && (
                                        <div className="got_offer">
                                            <span>Did not get offer</span>
                                        </div>
                                    )}
                                    {/* <a className="joindeLink" href="#">
                                        <i className="icon-like"></i> <span>JOINED</span>
                                    </a> */}
                                    <div className="offer_joined">
                                        <i></i>
                                        <a
                                            className="btn btn-link"
                                            target="_blank"
                                            // href={`${process.env.REACT_APP_APP_BASE_URL}/alumni/directory?universityId=${college.universityId}`}
                                            rel="noreferrer">
                                            {college.pgalumniCount} Alumni Joined
                                        </a>
                                    </div>
                                </div>
                                {model.isEditable && (
                                    <div className={`edit_section ${isActive ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                                        <button className="edit_section_toggle" onClick={handleClick}></button>
                                        <div className="edit_section_inner">
                                            <a className="edit_link" role="button" onClick={() => navigate(`/alumni/${model.id}/pg`)}>
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    ))}
                </div>
                {/* <OtherSimilarAlumni /> */}
                {state.data?.matchingAlumni?.data && state.data?.matchingAlumni?.data.length > 0 && (
                    <div className="graduation_carousel_wrap">
                        <CarouselComponent
                            key={'PostGradAlumni'}
                            data={state.data?.matchingAlumni?.data}
                            cardRenderer={AlumniPostGradCardControl}
                            itemsPerPage={4}
                            itemsPerSlide={2}
                            // title={title}
                            responsive={{
                                0: { items: 1 },
                                400: { items: 1 },
                                600: { items: 2 },
                                700: { items: 2 },
                                1000: { items: 2 },
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePostGraduate;
