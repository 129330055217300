import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

interface ReferralData {
    lead_first_name: string;
    lead_last_name: string;
    referral_first_name: string;
    referral_last_name: string;
    country_code: string;
    mobile: string;
    relationship: string | null;
    email_id: string | null;
}

const VerifyProgress: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState } = useContext(SessionContext);

    const schoolImage = localStorage.getItem('school-image') || sessionStorage.getItem('school-image');
    const instName = localStorage.getItem('school-name') || sessionStorage.getItem('school-name');

    const { schoolDomain } = useParams<{ schoolDomain: string }>();
    const lead_ref_id = localStorage.getItem('lead_ref_id') || sessionStorage.getItem('lead_ref_id');
    const location = useLocation();
    const [referralData, setReferralData] = useState<ReferralData | null>(null);
    const queryParams = new URLSearchParams(location.search);
    const referalInsertId = queryParams.get('referalInsertId');
    const referalId = queryParams.get('referalId');

    const [relationship, setRelationship] = useState<string | null>(null);
    const [apiMessage, setApiMessage] = useState<{ text: string; isSuccess: boolean }>({ text: '', isSuccess: false });

    const baseUrl = window.location.origin;
    let referLink = baseUrl+'/reference/'+schoolDomain+'/users-track?user='+lead_ref_id;
//alert(schoolImage);
    const validateForm = (): boolean => {
        if (!relationship) {
            setApiMessage({ text: 'Relationship selection is required.', isSuccess: false });
            return false;
        }
        return true;
    };

    const fetchData = async () => {
        try {
            const jwtToken = localStorage.getItem('user-jwt-token');
            const response = await axios.post(
                `${process.env.REACT_APP_BROADCAST_SERVER}/referral/get-referral`,
                { id: lead_ref_id },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            if (response.data && response.data.data) {
                const data = response.data.data as ReferralData;
                setReferralData(data);

                // Log extracted data to check if fields are correctly populated
                console.log('Referral Data:', data);
            } else {
                console.error('No referral data found.');
                setApiMessage({ text: 'No referral data found.', isSuccess: false });
            }
        } catch (error) {
            console.error('Error fetching referral data:', error);
            setApiMessage({ text: 'Error fetching referral data.', isSuccess: false });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            if (relationship === '0') {
                window.location.href = `/reference/${schoolDomain}/users-track`;
            } else {
                try {
                    await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/referral/update-referral`, {
                        id: lead_ref_id,
                        track_status: 1,
                        inst_name: instName,
                        inst_logo: schoolImage,
                        inst_domain: referLink,
                        lead_first_name: referralData?.lead_first_name,
                        lead_last_name: referralData?.lead_last_name,
                        referral_first_name: referralData?.referral_first_name,
                        referral_last_name: referralData?.referral_last_name,
                        email_id: referralData?.email_id,
                    });
                    setApiMessage({ text: 'Referral updated successfully.', isSuccess: true });
                    window.location.href = `/reference/${schoolDomain}/users-track`;
                } catch (error) {
                    setApiMessage({ text: 'An error occurred. Please try again.', isSuccess: false });
                }
            }
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100">
                            <img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" />
                        </a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
                        <div className="id-title text-center">Thank you!</div>
                        <div className="col-12 text-center font-16 font-500">
                            Wish to get updates about the progress of your referred candidate, and earn some rewards?
                        </div>
                        <div className="pb-5">
                            <form className="min-height-250 max-650 mx-auto" onSubmit={handleSubmit}>
                                <div className="row g-4 align-items-end">
                                    <div className="col-12">
                                        <div className="row g-4">
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Friend"
                                                        value="1"
                                                        onChange={(e) => setRelationship(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor="Friend">
                                                        <i></i>
                                                        <span>Yes</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Relative"
                                                        value="0"
                                                        onChange={(e) => setRelationship(e.target.value)}
                                                    />
                                                    <label className="form-check-label" htmlFor="Relative">
                                                        <i></i>
                                                        <span>No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn-green height-45 px-5 mx-auto">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {apiMessage.text && (
                                <div className={`alert ${apiMessage.isSuccess ? 'alert-success' : 'alert-danger'} mt-4`}>
                                    {apiMessage.text}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default VerifyProgress;
