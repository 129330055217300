import { Modal } from 'bootstrap';
import { useEffect, useState } from 'react';
import groupImage from '../../../../src/styles/images/group-43080.svg';
import PeerReviewService from '../../alumni_review/PeerReviewService';
const Home = () => {
    const [alumniJoined, setAlumniJoined] = useState(0);
    const [popupVisible, setPopupVisible] = useState(false);
    const loadInitialData = async () => {
        // Perform API calls using axiosClient to check if pending account request
        try {
            const userToApprove = await PeerReviewService.get();

            if (userToApprove.data.length > 0) {
                setAlumniJoined(userToApprove.data.length);
                const modalElement = document.getElementById('jobDescriptionModal');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    return (
        <>
            <div
                className="modal fade"
                id="jobDescriptionModal"
                tabIndex={-1}
                aria-labelledby="jobDescriptionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered max-700 mx-auto">
                    <div className="modal-content">
                        <div className="modal-header border-0 pt-5">
                            <h5 className="modal-title font-24 text-dark-blue text-center w-100" id="staticBackdropLabel">
                                Your immediate attention required!!
                            </h5>
                            <button type="button" className="btn-close btn-close-small" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0 px-md-5 pb-4 pb-sm-5">
                            <div className="placeholder-img text-center my-4">
                                <img src={groupImage} className="img-fluid" width="168" />
                            </div>
                            <div className="max-470 mx-auto">
                                <p className="text-center">
                                    <span className="font-600 text-dark-blue"> {alumniJoined} Newly joined</span> users are requesting for
                                    their accounts to be fully activated on this network as students and alumni of your school.
                                </p>
                                <p className="text-center">Help us speed up the process, by validating the requests.</p>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center py-3">
                                <a href="/alumni-review/admin" className="btn-green max-190 mx-auto">
                                    <span>View Requests</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main flex-1">
                <section className="milestoneSection">
                    <div className="container max-1140 px-lg-0">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="white-block m-w-90-parent white-block-notopborderradius h-100">
                                    <div className="m-w-90 max-1010 mx-auto pt-4 pt-md-0">
                                        <div className='d-flex justify-content-between align-items-start py-4"'>
                                            <div className="welcome-title">
                                                <h1>Welcome to Univariety!</h1>
                                                <p>We recommend below activities to get you started.</p>
                                            </div>
                                            <div className="dashboard smart-scroll">
                                                <a href="/home" className="btn-green">
                                                    <span>Jump to Dashboard</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Home;
