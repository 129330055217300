import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../../library/Core/SmartContext';

import { useNavigate, useParams } from 'react-router-dom';
import AlbumListingService from '../../../album/listing/AlbumListingService';
import AlbumCardControl from '../../../card-controls/AlbumCardControl';
import CarouselComponent from '../../../card-controls/CarouselComponent';
import JobCardControl from '../../../card-controls/JobCardControl';
import JobListingService from '../../../jobs/listing/JobListingService';
import SessionContext from '../../../../library/Core/SessionContext';
import ReactImageVideoviewer from 'react-image-video-viewer';
import { Modal } from 'bootstrap';
import { isSchoolAdmin } from '../../../../library/Core/SmartFunctions';
import GuidanceService from '../../../guidance/listing/GuidanceService';
import { UUID } from 'crypto';
import GuidanceCardControl from '../../../card-controls/GuidanceCardControl';

const ProfileContributions = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    const model = state.internal.model;
    const [albums, setAlbums] = useState<any[]>([]);
    const [guidanceVideos, setGuidanceVideos] = useState<any[]>([]);
    const [jobs, setJobs] = useState<any[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    // const userType = sessionStorage.getItem('user-type-code');
    const userType = sessionState?.userTypeCode;
    const { id, pageName } = useParams();
    const [showAlbum, setShowAlbum] = useState(false);
    const [images, setImages] = useState([] as any);

    const [popupVisible, setPopupVisible] = useState(false);
    const [jobDescription, setJobDescription] = useState('');


    const handleViewJobDescription = (description: string) => {
        console.log(description, " >> description 2 ");
        setJobDescription(description);
        setPopupVisible(true);
    };

    useEffect(() => {
        if (popupVisible) {
            const modalElement = document.getElementById('jobDescriptionModal');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();

                modalElement.addEventListener('hidden.bs.modal', () => {
                    setPopupVisible(false);
                });
            }
        }
    }, [popupVisible]);
    const extractVideoId = (url: string) => {
        if (url === undefined) return '';
        const videoIdMatch = url?.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        return videoIdMatch ? videoIdMatch[1] : '';
    };

    const handleShowAlbum = async (id: string) => {
        const response = await AlbumListingService.getById(id);
        // const selectedAlbumImages = response.data?.albumContent?.map((content: any) => ({ src: content?.link }));
        const selectedAlbumImages = response.data?.albumContent?.map((content: any) => {
            let link_type = 'photo';
            if (content?.link_type == 'video') {
                const videoId = extractVideoId(content?.youtube_link);
                content.link = `https://www.youtube.com/embed/${videoId}`;
                link_type = 'video';
            }
            return {
                url: content?.link,
                type: link_type,
                altTag: 'No Image'
            }
        });

        setImages(selectedAlbumImages);
        setShowAlbum(true);
    };


    const handleShowGuidanceVideo = async (videoLink: string) => {
        // const response = await AlbumListingService.getById(id);
        // const selectedAlbumImages = response.data?.albumContent?.map((content: any) => ({ src: content?.link }));
        // const selectedAlbumImages = response.data?.albumContent?.map((content: any) => {
            // let link_type = 'photo';
            // if (content?.link_type == 'video') {
                const videoId = extractVideoId(videoLink);
                let video = `https://www.youtube.com/embed/${videoId}`;
                // link_type = 'video';
            // }
            let selectedAlbumImages =  [{
                url: video,
                type: 'video',
                altTag: 'No Image'
            }]
        // });

        setImages(selectedAlbumImages);
        setShowAlbum(true);
    };

    const fetchAlbums = () => {
        let type = 'STUDENT';
        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];

        if (schoolAdminRoles.includes(sessionState?.userTypeCode as string) || sessionState?.userTypeCode==='SUPER_ADMIN') {
            type = "SCHOOL_ADMIN_ALUMNI";
        }

        AlbumListingService.get(type, id).then((albumsResponse) => {
            setAlbums(albumsResponse?.data.albums);
        });
    };

    const fetchGuidanceVideos = () => {
        GuidanceService.getById(id as UUID).then((guidanceResponse) => {
            setGuidanceVideos(guidanceResponse?.data?.guidanceVideos);
        });
    };

    const fetchJobs = () => {
        let searchBy = 'LISTED_BY_USER'
        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];

        if (schoolAdminRoles.includes(sessionState?.userTypeCode as string) || sessionState?.userTypeCode==='SUPER_ADMIN') {
            searchBy = "USER_LIST_BY_ADMIN";
        }

        if (searchBy == 'LISTED_BY_USER' && id != sessionState?.userId) {
            searchBy = 'LISTED_BY_OTHER_USER'
        }

        JobListingService.get({ searchBy: searchBy, userId: id }).then((jobsResponse) => {
            setJobs(jobsResponse.data.data);
            setIsLoading(false); // Assuming you want to set this only after jobs are loaded
        });
    };

    useEffect(() => fetchAlbums(), [state.internal?.refreshAlbum]);
    useEffect(() => fetchGuidanceVideos(), [state.internal?.refreshGuidance]);
    useEffect(() => fetchJobs(), [state.internal?.refreshJobs]);

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    return (
        <div>
            <div className="tab-pane fade active show" id="pills-contributions" role="tabpanel" aria-labelledby="pills-Contributions-tab">
                <div className="contributions-list">
                    <div className="contributions-block">
                        <div className="memoriessection">
                            {/* <div className="seactionheader">
                                <h3>Memories</h3>
                                <div className="countnbtn">
                                    <span className="count">{albums.length} memories found</span>
                                </div>
                            </div> */}
                            {albums.length === 0 && (
                                <div className="nolisting_wrap">
                                    <div className="nolisting">
                                        <div className="nolisting_icon">
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/undraw_photos_re_pvh3.svg`}
                                                width="158"
                                                alt=""
                                            />
                                        </div>
                                        <div className="nolisting_text">
                                            <p className="mt-3 mb-3 pb-1 font-18 font-400">
                                                Looks like {model.firstName} is yet to contribute any memory albums. Check out the albums
                                                created by other fellow alumni.
                                            </p>
                                            <a role="button" onClick={() => navigate(`/memories`)} className="btn btn-green">
                                                <span>View Memories</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <CarouselComponent
                                key={'Memories-' + albums.length + '-' + Date.now()}
                                title={'Memories'}
                                data={albums}
                                // cardRenderer={AlbumCardControl}
                                cardRenderer={(album: any, index: any) => <AlbumCardControl key={index} handleShowAlbum={handleShowAlbum} album={album} />}
                                itemsPerPage={2}
                                itemsPerSlide={1}
                                responsive={{
                                    0: { items: 1 },
                                    400: { items: 1 },
                                    600: { items: 2 },
                                    700: { items: 2 },
                                    1000: { items: 2 },
                                }}
                            />
                        </div>
                    </div>

                    <div className="contributions-block">
                        <div className="guidancesection">
                            {guidanceVideos.length === 0 && (
                                <div className="nolisting_wrap">
                                    <div className="nolisting">
                                        <div className="nolisting_icon">
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/undraw_photos_re_pvh3.svg`}
                                                width="158"
                                                alt=""
                                            />
                                        </div>
                                        <div className="nolisting_text">
                                            <p className="mt-3 mb-3 pb-1 font-18 font-400">
                                                Looks like {model.firstName} is yet to contribute any guidance videos. Check out the videos
                                                added by other fellow alumni.
                                            </p>
                                            <a role="button" onClick={() => navigate(`/guidance`)} className="btn btn-green">
                                                <span>View Guidance Videos</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row uni_profiles">
                            <CarouselComponent
                                key={'Guidance-' + guidanceVideos.length + '-' + Date.now()}
                                title={'Guidance Videos'}
                                data={guidanceVideos}
                                // cardRenderer={AlbumCardControl}
                                cardRenderer={(guidance: any, index: any) => <GuidanceCardControl key={index} handleShowGuidanceVideo={handleShowGuidanceVideo} guidance={guidance} />}
                                itemsPerPage={2}
                                itemsPerSlide={1}
                                responsive={{
                                    0: { items: 1 },
                                    400: { items: 1 },
                                    600: { items: 1 },
                                    700: { items: 2 },
                                    1000: { items: 2 },
                                }}
                            />
                            </div>
                            
                        </div>
                    </div>

                    {showAlbum && <ReactImageVideoviewer
                        open={showAlbum}
                        close={() => setShowAlbum(false)}
                        data={images}
                        startIndex={0}
                        showResourceCount={true}
                        onCloseCallback={() => setShowAlbum(false)}
                    // onNavigationCallback={(currentIndex) =>
                    //     console.log(`Current index: ${currentIndex}`)
                    // }
                    />}

                    <div className="contributions-block">
                        <div className="jobsandInternshipssection">
                            {jobs.length === 0 && (
                                <div className="nolisting_wrap">
                                    <div className="nolisting">
                                        <div className="nolisting_icon">
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/undraw_resume_re_hkth.svg`}
                                                width="160"
                                                alt=""
                                            />
                                        </div>
                                        <div className="nolisting_text">
                                            <p className="mt-3 mb-3 pb-1 font-18 font-400">
                                                Looks like {model.firstName} is yet to contribute any job listings. Check out the job
                                                listings created by other fellow
                                            </p>
                                            <a role="button" onClick={() => navigate(`/job-posting`)} className="btn btn-green">
                                                <span>View Jobs</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <CarouselComponent
                                key={'JobsAndInternships-' + jobs.length + '-' + Date.now()}
                                title={'Jobs And Internships'}
                                data={jobs}
                                // cardRenderer={JobCardControl}
                                cardRenderer={(job: any, index: any) => <JobCardControl key={index} handleViewJobDescription={handleViewJobDescription} {...job} />}
                                itemsPerPage={2}
                                itemsPerSlide={2}
                                responsive={{
                                    0: { items: 1 },
                                    400: { items: 1 },
                                    600: { items: 1 },
                                    700: { items: 1 },
                                    1000: { items: 1 },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="jobDescriptionModal"
                tabIndex={-1}
                aria-labelledby="jobDescriptionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="jobDescriptionModalLabel">
                                Job Description
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setPopupVisible(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{jobDescription}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => setPopupVisible(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileContributions;
