import React, { useState, ChangeEvent, FormEvent } from 'react';
import { axiosClient } from '../../../../library/Service/axiosClient';
import Swal from 'sweetalert2';

interface FormData {
  disposition: string;
  talked_to: string;
  comment: string;
  user_ref_id: string;
}

const AddCallLogModal = ({data}:any) => {
    let { model,setisModalOpen, modalAddLogElement,getlastLogHistory}=data;

    const [formData, setFormData] = useState<FormData>({
        disposition: '',
        talked_to: 'Alumni',
        comment: '',
        user_ref_id: ''
    });

  const handleInputChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      formData.user_ref_id = model.id;
      if(formData.disposition === ''){
        // alert('Please select disposition');
        Swal.fire({
            title: 'Error!',
            text: 'Please select disposition',
            icon: 'error',
            confirmButtonText: 'Close'
        });
          
        return;
      }
      const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/admin-call-log/add-new-call-log`, formData);
      if(response.data.success === true){
        setFormData({
            disposition: '',
            talked_to: 'Alumni',
            comment: '',
            user_ref_id: ''
        });
        // setisModalOpen(false);
        getlastLogHistory();
        document.getElementById('close_add_log')?.click();
      }
      else{
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

return (
  <div ref={(element) => {
    modalAddLogElement = element;
  }} className="modal fade px-3 show" id="add-call-log" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel"  aria-modal="true" role="dialog">
  <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
      <div className="modal-content">
          <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
              <h5 className="modal-title" id="staticBackdropLabel">Add Call Log </h5>
            
              <button id='close_add_log' type="button" className="btn-close btn-close-small m-0" data-bs-dismiss="modal" aria-label="Close" onClick={setisModalOpen(false)}></button>
          </div>
          <div className="modal-body p-4">

              <form className="needs-validation" id="addCallLogForm" onSubmit={handleSubmit}>
              {/* <form className="needs-validation" id="addCallLogForm" onSubmit={e => handleSubmit(e, setisModalOpen)}> */}

                 
                  <div className="row g-4">
                      <div className="col-md-6">
                          <label className="form-label" > Disposition</label>
                          <div className="form-group">
                              <select className="form-select  moveFunction" aria-label="ClassName" name="disposition" value={formData.disposition} onChange={handleInputChange}>
                                  <option value="" disabled={true}>Select Disposition</option>
                                  <option value="Call Back">Call Back</option>
                                  <option value="Information Collected">Information Collected</option>
                                  <option value="Busy"> Busy </option>
                                  <option value="Ringing No Response"> Ringing No Response </option>
                                  <option value="Invalid Number">Invalid Number </option>
                                  <option value="Information Collected Partly"> Information Collected Partly </option>
                                  <option value="Unable To Reach">Unable To Reach </option>
                                  <option value="Dropped A Year">Dropped A Year </option>
                                  <option value="Not Interested">Not Interested </option>
                                  <option value="Hung Up">Hung Up </option>
                                  <option value="Wrong Number">Wrong Number </option>
                                  <option value="Information Collected Whatsapp"> Information Collected Whatsapp </option>
                                  <option value="Switched Off">Switched Off </option>
                                  <option value="Test Call">Test Call </option>
                              </select>
                              <div className="invalid-feedback">
                                  Please Enter vaild Email Address
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6">
                          <label className="form-label" >Talked to</label>
                          <div className="form-group">
                              <div className="row py-2">
                                  <div className="col-auto">
                                      <div className="form-check d-flex flex-wrap">
                                          <input className="form-check-input me-2" name="talked_to" type="radio" id="talked_to_Alumni" value="Alumni" checked={formData.talked_to === "Alumni"} onChange={handleInputChange} />
                                          <label className="form-check-label font-16 font-500 flex-1 pt-1" >Alumni</label>
                                      </div>
                                  </div>
                                  <div className="col-auto">
                                      <div className="form-check d-flex flex-wrap">
                                          <input className="form-check-input me-2" name="talked_to" type="radio" id="talked_to_Parent" value="Parent" checked={formData.talked_to === "Parent"} onChange={handleInputChange} />
                                          <label className="form-check-label font-16 font-500 flex-1 pt-1" >Parent</label>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-xl-12">
                          <label className="form-label" >Comment</label>
                          <div className="form-group">
                              <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" name="comment" value={formData.comment} onChange={handleInputChange}></textarea>
                              <div className="invalid-feedback">
                                  Please Enter vaild Email Address
                              </div>
                          </div>
                      </div>


                 
                    
                      <div className="col-12">
                          <div className="row justify-content-end align-items-center">
                              {/* <div className="col-auto"><button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button></div> */}
                              <div className="col-auto"><button type="submit" className="btn btn-green max-135"><span>Submit</span></button></div>
                          </div>
                      </div>
                   
                      
                  </div>
           
                  

              </form>
          

          </div>
      </div>
  </div>
</div>
    );
  };

  export default AddCallLogModal;