import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SmartContext } from '../../library/Core/SmartContext';
import { formatFullName, getDomainValueForCode, getTimeAgo, isEmpty, isSchoolAdmin, isSuperAdmin } from '../../library/Core/SmartFunctions';
import useAlbum from '../album/listing/useAlbum';
import SessionContext from '../../library/Core/SessionContext';

// const AlbumCardControl = (album: any, handleShowAlbum: any) => {
const AlbumCardControl = (props: any) => {
    const { album, handleShowAlbum } = props;
    const { likeAlbum, saveAlbum } = useAlbum();
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const refresh = () => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'refreshAlbum', value: uuidv4() } });
    };

    const handleSaveAlbum = async (event: any, id: string) => {
        event.preventDefault();
        await saveAlbum(id, false);
        setTimeout(() => refresh(), 0);
    };

    const handleLikeAlbum = async (event: any, id: string) => {
        event.preventDefault();
        await likeAlbum(id, false);
        setTimeout(() => refresh(), 0);
    };

    return (
        <div key={album.id} className="col-lg-12 col-md-12 album_blk_parent pfusers">
            <div className="album_blk">
                <div 
                className="album_imgblk position-relative" 
                role="button"
                onClick={() => handleShowAlbum(album.id)}
                style={{ height: '200px' }}>
                    <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute">
                        Added by {getDomainValueForCode(album.userTypeCode, 'USER_TYPE_CODE', state)}
                    </span>

                    {/* <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_albumimg.jpg`} alt="" /> */}
                    <img src={album.link ?? `${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_albumimg.jpg`} alt="" />

                    <div className="album_imgvdo position-absolute"></div>
                </div>
                <div className="album_contblk d-flex flex-wrap justify-content-between align-items-start">
                    <div className="flex-1 Wrdrow">
                        <a style={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <h3 className="font-20 font-500 mb-2 text-start">{album.title}</h3>
                        </a>
                        <p className="text-gray-64 font-14 font-500 mb-2 text-start">Taken in - {`${album.year}`}</p>
                        <span className="border-lightgray mb-2"></span>
                        <a href="" className="d-flex align-items-start person_detls text-decoration-none">
                            <img
                                src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`}
                                style={{ height: '32px', width: '32px' }}
                                className="rounded-circle mt-1 me-2"
                            />

                            <div className="lstperdetails text-start">
                                <p className="mb-1 font-14 font-500 line-height-18 text-black">
                                    <span className="font-16">{formatFullName(album.studentFirstName, album.studentLastName)}</span>,{' '}
                                    {/* <em>Class of {album.studentPassOutYear}</em> */}
                                    <em>{album.programName}</em>
                                </p>

                                {/* <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                    Working As
                                    <span className="font-600 text-black">
                                        {album.studentJobDesignation} At {album.studentJobCompanyName}
                                    </span>
                                </p> */}
                                {!isEmpty(album.companyName) && (
                                    <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                        Working As{' '}
                                        <span className="font-600 text-black">
                                            {album.studentJobDesignation} At {album.companyName}
                                        </span>
                                    </p>
                                )}
                                {isEmpty(album.companyName) && !isEmpty(album.universityName) && (
                                    <p className="font-14 fst-italic font-500 text-gray-64 mb-0">
                                        Pursuing UG At{' '}
                                        <span className="font-600 text-black">{album.universityName}</span>
                                    </p>
                                )}
                            </div>
                        </a>
                    </div>
                    <div className="album_shareblk">
                    {album.studentId != sessionState?.id && !isSchoolAdmin() && !isSuperAdmin() && (
                        <a
                            className={`btn btnicon listblk_icon me-2 me-sm-0 circle-effect ${album.isFavorite ? 'btn-dark-blue' : ''}`}
                            id="MakeSave_34"
                            role="button"
                            onClick={(event) => handleSaveAlbum(event, album.id)}>
                            <span
                                className={`icon-ribbon-invert font-18 icon ${album.isFavorite ? 'text-white' : ''}`}
                                id="SaveIcon_34"></span>
                        </a>
                    )}
                    {album.studentId != sessionState?.id && !isSchoolAdmin() && !isSuperAdmin() && (
                        <a
                            className={`btn btnicon listblk_icon circle-effect ${album.isLiked ? 'btn-dark-blue' : ''}`}
                            id="MakeLike_34"
                            role="button"
                            onClick={(event) => handleLikeAlbum(event, album.id)}>
                            <span className={`icon-like font-18 icon ${album.isLiked ? 'text-white' : ''}`} id="LikeIcon_34"></span>
                        </a>
                    )}
                    </div>
                </div>
                <div className="albumblk_footer d-flex justify-content-between align-items-center">
                    <p className="m-0 font-14 font-500 text-gray-64">
                    <em>Posted {getTimeAgo(album.createdAt)}</em>
                    </p>
                </div>
            </div>
            <div id="success_save_34"></div>
        </div>
    );
};

export default AlbumCardControl;
