import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const Referrence: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const [instName, setInstName] = useState<string>('');
    const { schoolDomain } = useParams<{ schoolDomain: string }>();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [groupId, setGroupId] = useState<string | null>(null);
    const [instituteId, setInstituteId] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        countryCode: '+91',
        contactNumber: '',
        relationship: '',
        remarks: '',
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        contactNumber: '',
        relationship: '',
    });

    const [apiMessage, setApiMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                const fetchedGroupId = response.data.institute.instituteGroupId;
                const fetchedInstituteId = response.data.institute.id;
                const instName = response.data.institute.name;
                console.log(logoUrl);
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
                localStorage.setItem('GroupId', fetchedGroupId);
                sessionStorage.setItem('Group-Id', fetchedGroupId);
                localStorage.setItem('Inst-ID', fetchedInstituteId);
                sessionStorage.setItem('Inst-ID', fetchedInstituteId);
                localStorage.setItem('school-name', instName);
                sessionStorage.setItem('school-name', instName);
                setInstName(response.data.institute.name);
                setGroupId(fetchedGroupId);
                setInstituteId(fetchedInstituteId);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
    }, [schoolDomain]);

    const validateForm = () => {
        let errors = { firstName: '', lastName: '', contactNumber: '', relationship: '' };
        let isValid = true;

        if (!formData.firstName) {
            errors.firstName = 'First name is required';
            isValid = false;
        }
        if (!formData.lastName) {
            errors.lastName = 'Last name is required';
            isValid = false;
        }
        if (!formData.contactNumber || !/^\d{10}$/.test(formData.contactNumber)) {
            errors.contactNumber = 'A valid 10-digit contact number is required';
            isValid = false;
        }
        if (!formData.relationship) {
            errors.relationship = 'Please select your relationship with the candidate';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({
            ...prevState,
            relationship: e.target.value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/referral/add-inquiry`, {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    country_code: formData.countryCode,
                    contact_number: formData.contactNumber,
                    institute_id: instituteId,
                    source: 'referral',
                    relationship: formData.relationship,
                    institute_group_id: groupId,
                });
                const referralInsertId = response.data.referralInsertId;
                setApiMessage(response.data.message);
                setIsSuccess(true);
                setTimeout(() => {
                    // Redirect to the page with referralInsertId in the URL
                    localStorage.setItem('lead_ref_id', referralInsertId);
                    sessionStorage.setItem('lead_ref_id ', referralInsertId);
                    window.location.href = `/reference/${schoolDomain}/users-email-otp`;
                }, 2000);
            } catch (error) {
                console.error('Error submitting referral:', error);
                setApiMessage('An error occurred while submitting the referral.');
            }
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100">
                            <img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" />
                        </a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
                        <div className="id-title text-center">Refer a Lead</div>
                        <div className="sep blue"></div>
                        <div className="pb-5">
                            <form className="min-height-250 max-650 mx-auto" onSubmit={handleSubmit}>
                                <div className="row g-4 align-items-end">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="firstName" className="form-label">
                                            Lead’s First Name*
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-46 border-0"
                                            id="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                        {formErrors.firstName && <small className="text-danger">{formErrors.firstName}</small>}
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <label htmlFor="lastName" className="form-label">
                                            Lead’s Last Name*
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-46 border-0"
                                            id="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                        {formErrors.lastName && <small className="text-danger">{formErrors.lastName}</small>}
                                    </div>

                                    <div className="col-12">
                                        <label className="form-label">Relationship with Candidate*</label>
                                        <div className="row g-4">
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Friend"
                                                        value="Friend"
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="Friend">
                                                        <i></i>
                                                        <span>Friend</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Relative"
                                                        value="Relative"
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="Relative">
                                                        <i></i>
                                                        <span>Relative</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Colleague"
                                                        value="Colleague"
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="Colleague">
                                                        <i></i>
                                                        <span>Colleague</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check p-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="IDENTIFY"
                                                        id="Others"
                                                        value="Others"
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="Others">
                                                        <i></i>
                                                        <span>Others</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {formErrors.relationship && <small className="text-danger">{formErrors.relationship}</small>}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="contactNumber" className="form-label">
                                            Mobile number (with country code)*
                                        </label>
                                        <div className="row g-3">
                                            <div className="col-auto">
                                                <select
                                                    id="countryCode"
                                                    name="countryCode"
                                                    className="form-select form-control country-code w-90"
                                                    value={formData.countryCode}
                                                    onChange={handleChange}>
                                                    <optgroup label="Top Countries">
                                                        <option value="91">+91</option>
                                                        <option value="44">+44</option>
                                                        <option value="1">+1</option>
                                                        <option value="61">+61</option>
                                                        <option value="49">+49</option>
                                                        <option value="7">+7</option>
                                                        <option value="86">+86</option>
                                                        <option value="65">+65</option>
                                                        <option value="971">+971</option>
                                                        <option value="81">+81</option>
                                                    </optgroup>
                                                    <optgroup label="----------------------">
                                                        <option value="0000">+0000</option>
                                                        <option value="1242">+1242</option>
                                                        <option value="1246">+1246</option>
                                                        <option value="1264">+1264</option>
                                                        <option value="1268">+1268</option>
                                                        <option value="1345">+1345</option>
                                                        <option value="1441">+1441</option>
                                                        <option value="1473">+1473</option>
                                                        <option value="1649">+1649</option>
                                                        <option value="1664">+1664</option>
                                                        <option value="1670">+1670</option>
                                                        <option value="1671">+1671</option>
                                                        <option value="1758">+1758</option>
                                                        <option value="1767">+1767</option>
                                                        <option value="1784">+1784</option>
                                                        <option value="1809">+1809</option>
                                                        <option value="1868">+1868</option>
                                                        <option value="1876">+1876</option>
                                                        <option value="20">+20</option>
                                                        <option value="212">+212</option>
                                                        <option value="213">+213</option>
                                                        <option value="216">+216</option>
                                                        <option value="218">+218</option>
                                                        <option value="220">+220</option>
                                                        <option value="221">+221</option>
                                                        <option value="222">+222</option>
                                                        <option value="223">+223</option>
                                                        <option value="224">+224</option>
                                                        <option value="225">+225</option>
                                                        <option value="226">+226</option>
                                                        <option value="227">+227</option>
                                                        <option value="228">+228</option>
                                                        <option value="229">+229</option>
                                                        <option value="230">+230</option>
                                                        <option value="231">+231</option>
                                                        <option value="232">+232</option>
                                                        <option value="233">+233</option>
                                                        <option value="235">+235</option>
                                                        <option value="236">+236</option>
                                                        <option value="237">+237</option>
                                                        <option value="238">+238</option>
                                                        <option value="239">+239</option>
                                                        <option value="240">+240</option>
                                                        <option value="241">+241</option>
                                                        <option value="242">+242</option>
                                                        <option value="245">+245</option>
                                                        <option value="246">+246</option>
                                                        <option value="248">+248</option>
                                                        <option value="249">+249</option>
                                                        <option value="250">+250</option>
                                                        <option value="251">+251</option>
                                                        <option value="252">+252</option>
                                                        <option value="253">+253</option>
                                                        <option value="254">+254</option>
                                                        <option value="255">+255</option>
                                                        <option value="256">+256</option>
                                                        <option value="257">+257</option>
                                                        <option value="258">+258</option>
                                                        <option value="260">+260</option>
                                                        <option value="261">+261</option>
                                                        <option value="262">+262</option>
                                                        <option value="263">+263</option>
                                                        <option value="264">+264</option>
                                                        <option value="265">+265</option>
                                                        <option value="266">+266</option>
                                                        <option value="267">+267</option>
                                                        <option value="268">+268</option>
                                                        <option value="269">+269</option>
                                                        <option value="27">+27</option>
                                                        <option value="284">+284</option>
                                                        <option value="290">+290</option>
                                                        <option value="291">+291</option>
                                                        <option value="297">+297</option>
                                                        <option value="298">+298</option>
                                                        <option value="299">+299</option>
                                                        <option value="30">+30</option>
                                                        <option value="31">+31</option>
                                                        <option value="32">+32</option>
                                                        <option value="33">+33</option>
                                                        <option value="34">+34</option>
                                                        <option value="340">+340</option>
                                                        <option value="350">+350</option>
                                                        <option value="351">+351</option>
                                                        <option value="352">+352</option>
                                                        <option value="353">+353</option>
                                                        <option value="354">+354</option>
                                                        <option value="356">+356</option>
                                                        <option value="357">+357</option>
                                                        <option value="358">+358</option>
                                                        <option value="359">+359</option>
                                                        <option value="36">+36</option>
                                                        <option value="370">+370</option>
                                                        <option value="371">+371</option>
                                                        <option value="372">+372</option>
                                                        <option value="373">+373</option>
                                                        <option value="374">+374</option>
                                                        <option value="375">+375</option>
                                                        <option value="376">+376</option>
                                                        <option value="377">+377</option>
                                                        <option value="378">+378</option>
                                                        <option value="379">+379</option>
                                                        <option value="38">+38</option>
                                                        <option value="380">+380</option>
                                                        <option value="385">+385</option>
                                                        <option value="386">+386</option>
                                                        <option value="387">+387</option>
                                                        <option value="389">+389</option>
                                                        <option value="39">+39</option>
                                                        <option value="40">+40</option>
                                                        <option value="41">+41</option>
                                                        <option value="420">+420</option>
                                                        <option value="421">+421</option>
                                                        <option value="423">+423</option>
                                                        <option value="43">+43</option>
                                                        <option value="45">+45</option>
                                                        <option value="46">+46</option>
                                                        <option value="47">+47</option>
                                                        <option value="48">+48</option>
                                                        <option value="500">+500</option>
                                                        <option value="501">+501</option>
                                                        <option value="502">+502</option>
                                                        <option value="503">+503</option>
                                                        <option value="504">+504</option>
                                                        <option value="505">+505</option>
                                                        <option value="506">+506</option>
                                                        <option value="507">+507</option>
                                                        <option value="508">+508</option>
                                                        <option value="509">+509</option>
                                                        <option value="51">+51</option>
                                                        <option value="52">+52</option>
                                                        <option value="53">+53</option>
                                                        <option value="54">+54</option>
                                                        <option value="55">+55</option>
                                                        <option value="56">+56</option>
                                                        <option value="57">+57</option>
                                                        <option value="58">+58</option>
                                                        <option value="581">+581</option>
                                                        <option value="590">+590</option>
                                                        <option value="591">+591</option>
                                                        <option value="592">+592</option>
                                                        <option value="593">+593</option>
                                                        <option value="594">+594</option>
                                                        <option value="595">+595</option>
                                                        <option value="596">+596</option>
                                                        <option value="597">+597</option>
                                                        <option value="598">+598</option>
                                                        <option value="599">+599</option>
                                                        <option value="60">+60</option>
                                                        <option value="62">+62</option>
                                                        <option value="63">+63</option>
                                                        <option value="64">+64</option>
                                                        <option value="66">+66</option>
                                                        <option value="670">+670</option>
                                                        <option value="672">+672</option>
                                                        <option value="673">+673</option>
                                                        <option value="674">+674</option>
                                                        <option value="675">+675</option>
                                                        <option value="676">+676</option>
                                                        <option value="677">+677</option>
                                                        <option value="678">+678</option>
                                                        <option value="679">+679</option>
                                                        <option value="680">+680</option>
                                                        <option value="681">+681</option>
                                                        <option value="682">+682</option>
                                                        <option value="685">+685</option>
                                                        <option value="686">+686</option>
                                                        <option value="687">+687</option>
                                                        <option value="688">+688</option>
                                                        <option value="689">+689</option>
                                                        <option value="690">+690</option>
                                                        <option value="691">+691</option>
                                                        <option value="692">+692</option>
                                                        <option value="82">+82</option>
                                                        <option value="84">+84</option>
                                                        <option value="850">+850</option>
                                                        <option value="852">+852</option>
                                                        <option value="853">+853</option>
                                                        <option value="855">+855</option>
                                                        <option value="856">+856</option>
                                                        <option value="870">+870</option>
                                                        <option value="880">+880</option>
                                                        <option value="886">+886</option>
                                                        <option value="90">+90</option>
                                                        <option value="92">+92</option>
                                                        <option value="93">+93</option>
                                                        <option value="94">+94</option>
                                                        <option value="95">+95</option>
                                                        <option value="960">+960</option>
                                                        <option value="961">+961</option>
                                                        <option value="962">+962</option>
                                                        <option value="963">+963</option>
                                                        <option value="964">+964</option>
                                                        <option value="965">+965</option>
                                                        <option value="966">+966</option>
                                                        <option value="967">+967</option>
                                                        <option value="968">+968</option>
                                                        <option value="972">+972</option>
                                                        <option value="973">+973</option>
                                                        <option value="974">+974</option>
                                                        <option value="975">+975</option>
                                                        <option value="976">+976</option>
                                                        <option value="977">+977</option>
                                                        <option value="98">+98</option>
                                                        <option value="992">+992</option>
                                                        <option value="993">+993</option>
                                                        <option value="994">+994</option>
                                                        <option value="995">+995</option>
                                                        <option value="996">+996</option>
                                                        <option value="998">+998</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className="col g-3">
                                                <input
                                                    type="text"
                                                    className="form-control height-46 border-0"
                                                    id="contactNumber"
                                                    value={formData.contactNumber}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        {formErrors.contactNumber && <small className="text-danger">{formErrors.contactNumber}</small>}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="remarks" className="form-label">
                                            Remarks (Optional)
                                        </label>
                                        <textarea
                                            className="form-control h-auto"
                                            rows={3}
                                            id="remarks"
                                            value={formData.remarks}
                                            onChange={handleChange}></textarea>
                                    </div>

                                    <div className="col-12">
                                        <button type="submit" className="btn-green height-45 px-5 mx-auto">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {apiMessage && <div className={`alert ${isSuccess ? 'alert-success' : 'alert-danger'} mt-4`}>{apiMessage}</div>}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Referrence;
