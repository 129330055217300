import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { getDomainElementsForGivenYearRange } from '../../../../library/Service/domainService';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    // const formValidationPassed = isFormValid(state, dispatch, ['postGradCollegeApplied']);
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    console.log('customValidationPassed' + customValidationPassed);
    const hasDonePg = !state?.data?.profile?.haveNotDonePg;

    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && (!customValidationPassed)) {
        alert('Please correct the form errors!');
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }


         const url = sessionState?.userTypeCode === 'SUPER_ADMIN' 
         ? 'moengage-pg-updation' 
         : 'moengage-multiple-pg-updation';

        // Send event to Moengage PG College
        sendMoEngageEvent(state?.data,url);

    // if (!state.data.profile.haveNotDonePg && !isFormValid(state, dispatch)) {
    //     alert('Please correct the form errors!');
    //     return;
    // }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            postGradCollege: state?.data['postGradCollege'],
            postGradCollegeJoined: state?.data['postGradCollegeJoined'],
            postGradCollegeApplied: state?.data['postGradCollegeApplied'],
            profile: {
                ...state?.data?.profile,
                appliedToNoOtherUgColleges: state.data.profile.appliedToNoOtherUgColleges,
            },
        })
        // postGradCollege: hasDonePg ? state?.data['postGradCollege'] : [{}],
    ).then((response) => {
        if (response?.status === 200) {
            navigateToNextStep(AlumniProfileEditScreens.POST_GRADUATE_DETAILS, {...state,data:{...state.data,postGradCollege:response?.data?.postGradCollege}}, dispatch, sessionState, sessionDispatch);

            // Call the API here to update the status as ACTIVE & COMPLETE.
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile/pg`);
            // });
        }
    });
};

export const populateEndYearOnStartYearChange = (props: ControlOnChangeArguments) => {
    if (props.control.id === 'startYear') {
        props.dispatch({
            type: 'SET_DOMAIN',
            payload: {
                key: 'PG_END_YEAR_CODE',
                value: getDomainElementsForGivenYearRange('PG_END_YEAR_CODE', parseInt(props.value), parseInt(props.value) + 3),
            },
        });
    }
};

export const onDidYouReceiveScholarshipChange = (props: ControlOnChangeArguments) => {
    if (!props.value)
        props.dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'postGradCollegeJoined.scholarshipAmount', errorMessages: [] },
        });
    return true;
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.POST_GRADUATE_DETAILS, state, dispatch);
};

export const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    let isFormValid = true;
    const collages = state?.data['postGradCollege'];
    const errorMessages = [] as any[];
    const joinedCollege = state.data.postGradCollegeJoined;

    if (isEmpty(joinedCollege?.universityId) && isEmpty(joinedCollege?.otherUniversity)) {
        isFormValid = false;
        setError('postGradCollegeJoined.universityId', [`Please enter "My Postgraduate Degree Was From"`], dispatch);
    }

    if(isEmpty(joinedCollege?.natureOfStudyCode)){
        isFormValid = false;
        setError('postGradCollegeJoined.natureOfStudyCode', [`Please select "The Nature of Your Degree"`], dispatch);
    }

    if(isEmpty(joinedCollege?.fieldOfStudyCode)){
        isFormValid = false;
        setError('postGradCollegeJoined.fieldOfStudyCode', [`Please select "Your Field of Study"`], dispatch);
    }

    if(isEmpty(joinedCollege?.startYear)){
        isFormValid = false;
        setError('postGradCollegeJoined.startYear', [`Please select "Start Year"`], dispatch);
    }

    if(isEmpty(joinedCollege?.endYear)){
        isFormValid = false;
        setError('postGradCollegeJoined.endYear', [`Please select your "End Year"`], dispatch);
    }

    if (isEmpty(joinedCollege?.entranceTestCode) && isEmpty(joinedCollege?.otherExam) && joinedCollege?.didYouGiveEntranceTest === true) {
        isFormValid = false;
        setError('postGradCollegeJoined.entranceTestCode', [`Please enter Entrance Test details`], dispatch);
    }

    //collages.forEach((college: any, index: number) => {
    // if (college.didYouGiveEntranceTest === true) {
    //     // Both are null then user has to select one
    //     if (isEmpty(college.entranceTestCode) && isEmpty(college.otherExam)) {
    //         isFormValid = false;
    //         setError(`postGradCollege.${index}.didYouGiveEntranceTest`, [`Please enter "Entrance Test" details`], dispatch);
    //     } else {
    //         setError(`postGradCollege.${index}.didYouGiveEntranceTest`, [], dispatch);
    //     }
    // }
    // Both are null then user has to select one
    // if (isEmpty(college?.universityId) && isEmpty(college?.otherUniversity)) {
    //     isFormValid = false;
    //     setError(`postGradCollege.${index}.universityId`, [`Please enter "I Pursued My PG From"`], dispatch);
    // } else {
    //     setError(`postGradCollege.${index}.universityId`, [], dispatch);
    // }
    // });

    //Check error in applied univerity case,
    // console.log("state.internal['profile.haveNotDonePg']" + state.data['profile']);
    // console.log(state.data['profile'].haveNotDonePg);
    const appliedCount = state?.data?.postGradCollegeApplied.filter(
        (college: any) => !isEmpty(college.universityId) || !isEmpty(college?.otherUniversity)
    )?.length;

    // console.log('appliedCount' + appliedCount);
    if (appliedCount === 0 && state.data['profile'].appliedToNoOtherPgColleges === false) {
        errorMessages.push('Please select at least one college to which you have applied.');

        isFormValid = false;
    }

    const notFilledOfferStatusCount = state?.data?.postGradCollegeApplied.filter(
        (college: any) => (!isEmpty(college?.universityId) || !isEmpty(college?.otherUniversity)) && isEmpty(college?.collegeApplicationResultCode)
    )?.length;

    if (notFilledOfferStatusCount > 0) {
        errorMessages.push('Please select offer status.');
        isFormValid = false;
    }

    // Dispatch the validation errors to the state
    dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'postGradCollegeApplied', errorMessages },
    });

    return isFormValid;
};

const sendMoEngageEvent = async (user:any,url:any) => {    
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/${url}`, {
            userInfo: user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
};