import UnlockImage from '../../../../styles/images/unlock.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import profilePictureVisual from '../../../../styles/images/profile_picture_visual.png';
import { useContext, useEffect, useState } from 'react';
import SessionContext from '../../../../library/Core/SessionContext';

let SchoolLogo = sessionStorage.getItem('school-image') as any;

var stepMessage: any = sessionStorage.getItem('step-message');

const AlumniProfileImageLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);
    // const [userStatusCode, setUserStatusCode] = useState(sessionStorage.getItem('user-status-code'));
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;

    const [profilePercentage, setprofilePercentage] = useState<any>('');
    useEffect(() => {
        const profilePercentage = sessionStorage.getItem('profile-percentage') ? sessionStorage.getItem('profile-percentage') : 0;
        setprofilePercentage(profilePercentage);
    }, [sessionStorage.getItem('profile-percentage')]);

    // useEffect(() => {
    //     setUserStatusCode(sessionStorage.getItem('user-status-code'));
    // }, []);

    return (
        <>
            <div className="main-wrap">
                <div className="leftPane">
                    <div className="leftPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="leftPaneHeaderWrap">
                                <div className="leftPaneHeader">
                                    <img src={SchoolLogo} alt="" />
                                    <label className="user_name">
                                        Hey <span>{`${sessionState?.firstName}  ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                                {(profilePercentage != 0 || (stepMessage !== null && stepMessage !== '')) && (
                                    <div className="global bg_transparant show" data-pai="75">
                                        {stepMessage !== null && stepMessage !== '' && (
                                            <div
                                                className={profilePercentage != 0 ? 'unlock green_recom_bg_left' : 'unlock green_recom_bg'}>
                                                <div className="img">
                                                    <img src={UnlockImage} alt="" />
                                                </div>
                                                <div className="text">{stepMessage}</div>
                                            </div>
                                        )}
                                        {profilePercentage != 0 && (
                                            <div className="progress_svg">
                                                <CircularProgressbar
                                                    value={profilePercentage}
                                                    text={`${profilePercentage}%`}
                                                    styles={buildStyles({
                                                        // This is in units relative to the 100x100px
                                                        // SVG viewbox.
                                                        textSize: '25px',
                                                        //   pathColor: `rgba(62, 152, 199, ${profilePercentage / 100})`,
                                                        textColor: '#000',
                                                        //   trailColor: '#d6d6d6',
                                                    })}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="leftPaneBodyWrap">
                            <div className="leftPaneBody">
                                <div className="qs_body aos-init aos-animate" data-aos="fade-right" data-nextpage="undergraduate.html">
                                    <div className="profile_wrap">
                                        <div className="steps_questions">{props.children}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightPane basic-information">
                    <div className="rightPaneWrap">
                        {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                            <div className="rightPaneHeaderWrap">
                                <div className="rightPaneHeader">
                                    <label className="user_name">
                                        Hey <span>{`${sessionState?.firstName}  ${sessionState?.lastName}`}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="rightPaneBodyWrap">
                            <div className="rightPaneBody" data-aos="fade-left">
                                <div className="step step_1">
                                    <a
                                        data-bs-toggle="collapse"
                                        data-bs-target="#listed_points"
                                        href=""
                                        className="mobile-step-block"
                                        aria-expanded="false"></a>
                                    <div className="textDetail">
                                        <h2 className="stepHeader">Why Update Your Profile Picture?</h2>
                                        <div className="listed_points" id="listed_points">
                                            <ul>
                                                <li>
                                                    Adding a Profile Picture can bring 5X more views to your Profile, and increase
                                                    interactions by 10X!
                                                </li>
                                            </ul>
                                        </div>
                                        <a href="" className="step_header_wrap_link">
                                            Read <span className="more">More</span>
                                            <span className="less">Less</span>
                                        </a>
                                    </div>
                                    <div className="step_img mt-3">
                                        <img
                                            src={profilePictureVisual}
                                            className="img-fluid float_horizontal_animate"
                                            width="100%"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a data-bs-toggle="collapse" data-bs-target="#listed_points" href="" className="bg-drop" aria-expanded="false"></a>
            </div>
        </>
    );
};

export default AlumniProfileImageLayout;
