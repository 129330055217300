import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleNext, onStartOrEndYearChange } from './OutcomeActions';
import OutcomeLayout from './OutcomeLayout';
import SessionContext from '../../../../library/Core/SessionContext';
import axios from 'axios';
import { isEmpty } from '../../../../library/Core/SmartFunctions';

const Outcome = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');
    const { sessionState } = useContext(SessionContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            ROUTER_NAVIGATE: navigate,
            REDIRECT: setRedirectUrl,
            startYear: onStartOrEndYearChange,
            sessionState: sessionState,
        },
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    useEffect(() => {
        if(sessionState?.userTypeCode == 'SUPER_ADMIN'){
            if (localStorage.getItem('selectedInstitute') == undefined) {
                navigate(`/alumni/${data?.id}/select-institute`);
            }
            // else
            // {
            //     const selectedInstitute :string = ''+localStorage.getItem('selectedInstitute');
            //     dispatch({
            //         type: 'DATA_INIT',
            //         payload: { formConfig, data : {...data, selectedInstitute : JSON.parse(selectedInstitute)}, routeInfo },
            //     });
            // }
        }
        
        const loadSchoolSettings = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_PAGE_CONFIG_SERVER}/v1/appConfig/outcome-page-program-domain-data/${data?.selectedInstitute?.instituteId}`
            );
            const domainMap = new Map([...globalState.domain]);
            console.log(internal, '  internal  36 ');
            domainMap.set('INSTITUTE_PROGRAM_CODE', response.data);
            const domain = addUserSpecificDomains(new Map([...domainMap]), data);
            dispatch({
                type: 'DATA_INIT',
                payload: { formConfig, data, domain, routeInfo },
            });
        };
        if(!isEmpty(data?.selectedInstitute)) loadSchoolSettings();
    }, []);

    let postProgramCompletionStatusCode = state?.data?.selectedInstitute?.postProgramCompletionStatusCode;
    const higherStudyOptions = ['JOINED_SCHOOL', 'JOINED_UG', 'JOINED_PG', 'JOINED_DOCTORAL'];
    if (higherStudyOptions.includes(postProgramCompletionStatusCode)) {
        postProgramCompletionStatusCode = 'WENT_FOR_HIGHER_STUDIES';

        let selectedInstitute = { ...state?.data?.selectedInstitute };
        selectedInstitute.postProgramCompletionStatusCode = postProgramCompletionStatusCode;

        dispatch({ type: 'REFRESH_DATA', payload: { data: { ...state?.data, selectedInstitute: selectedInstitute } } });
    }

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <OutcomeLayout>{!state.flags.isDataLoading && <PageBuilder />}</OutcomeLayout>
        </SmartContext.Provider>
    );
};

export default Outcome;
