import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const UserTrack: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const [instName, setInstName] = useState<string>('');
    const { schoolDomain } = useParams<{ schoolDomain: string }>();
    const lead_ref_id = localStorage.getItem('lead_ref_id') || sessionStorage.getItem('lead_ref_id');
    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('user');
    const userid = userId || lead_ref_id;
      
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        countryCode: '+91',
        contactNumber: '',
        relationship: '',
        remarks: '',
    });
    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        contactNumber: '',
        relationship: '',
    });
    const [apiMessage, setApiMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const [referralData, setReferralData] = useState<{
        lead_first_name: string;
        lead_last_name: string;
        country_code: string;
        mobile: string;
        relationship: string | null;
    } | null>(null);

    const [trackData, setTrackData] = useState<{ track_on: string; track_status: number }[]>([]);

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
                const instName = response.data.institute.name;
                setInstName(instName);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
    }, [schoolDomain]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const jwtToken = localStorage.getItem('user-jwt-token');
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/referral/get-referral`,
                    { id: userid },
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data && response.data.data) {
                    setReferralData(response.data.data);
                } else {
                    console.error('No referral data found.');
                }
            } catch (error) {
                console.error('Error fetching referral data:', error);
            }
        };

        const fetchTrackData = async () => {
            try {
                const jwtToken = localStorage.getItem('user-jwt-token');
                const response = await axios.post(
                    `${process.env.REACT_APP_BROADCAST_SERVER}/referral/get-track-referral`,
                    { id: userid }, // Replace with dynamic ID if needed
                    {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data && response.data.data) {
                    setTrackData(response.data.data);
                    console.log('Fetched trackData:', response.data.data); // Debugging output
                } else {
                    console.error('No track data found in response.');
                }
            } catch (error) {
                console.error('Error fetching track data:', error);
            }
        };
        if (userid) {
            fetchData();
            fetchTrackData();
        } else {
            console.error('No referral id provided');
        }
    }, [userid]);

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100">
                            <img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" />
                        </a>
                    </div>
                    <div className="max-1140 pt-3 pt-sm-5 px-lg-0 pb-5 overflow-hidden mx-auto">
                        <div className="row g-2 mb-4 align-items-center">
                            <div className="col-sm">
                                <div className="d-flex flex-column">
                                    <h3 className="font-35 font-600 mb-0 block-title text-white text-center">Referral Progress Tracker</h3>
                                    <p className="mb-0 text-white text-center">
                                        You referred a candidate for admission to{' '}
                                        <span className="font-500">Vellore Institute of Technology</span>
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-sm-auto">
                            <div className="referred-college-logo">
                                <img src={schoolImage || ''} alt="College Logo" />
                            </div>
                        </div> */}
                        </div>

                        <div className="referral-progress-wrap pt-5">
                            <div className="row g-4">
                                <div className="col-md-4 col-sm-6">
                                    {referralData && (
                                        <div className="referral-progress-card">
                                            <div className="profileimg-name bg-white">
                                                <div className="profile-name text-dark">
                                                    {referralData.lead_first_name} {referralData.lead_last_name}
                                                </div>
                                            </div>
                                            <div className="referral-profile-detail">
                                                <ul>
                                                    <li>
                                                        <i className="hand-share"></i>
                                                        <div className="d-flex flex-column">
                                                            <span className="font-500">Relationship</span>
                                                            <span>{referralData.relationship}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <i className="mobile"></i>
                                                        <div className="d-flex flex-column">
                                                            <span className="font-500">Mobile</span>
                                                            <a href={`tel:+${referralData.country_code}${referralData.mobile}`}>
                                                                {referralData.country_code} {referralData.mobile}
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-8 col-sm-6">
                                    <div className="referral-progress-track p-5">
                                        <ul>
                                            {[
                                                'First Interaction Completed',
                                                'Application active',
                                                'Admission Offer Made',
                                                'Admission Offer Accepted',
                                                'Enrolled',
                                            ].map((label, index) => (
                                                <li key={index} className={index < trackData.length ? 'completed' : ''}>
                                                    <div className="icon interaction">
                                                        <img
                                                            src={`https://www.univariety.xyz/prototype/school_dashboard/images/${
                                                                index === 0
                                                                    ? 'first-interaction-completed'
                                                                    : index === 1 || index === 3
                                                                    ? 'application-completed'
                                                                    : index === 2
                                                                    ? 'admission-offer-made'
                                                                    : 'enroll'
                                                            }.svg`}
                                                            className="img-fluid"
                                                            width="37"
                                                            alt={label}
                                                        />
                                                    </div>
                                                    <div className="dot">
                                                        <i></i>
                                                    </div>
                                                    <div className="track-label">
                                                        <div className="font-16">{label}</div>
                                                        <div className="font-14">
                                                            {index < trackData.length
                                                                ? new Date(trackData[index].track_on).toLocaleDateString()
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default UserTrack;
