import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SmartContext } from '../../library/Core/SmartContext';
import { extractVideoId } from '../../library/Core/SmartFunctions';

const GuidanceCardControl = (props: any) => {
    const { guidance, handleShowGuidanceVideo } = props;
    const { state, dispatch } = useContext(SmartContext);

    const refresh = () => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'refreshGuidance', value: uuidv4() } });
    };

    return (
        <div key={guidance.id} className="col-lg-12 col-md-12 album_blk_parent pfusers">
            <div className="w-100" key={guidance.id}>
                <a className="video_block video-btn w-100 ">
                    <span className="video_block_img w-100">
                        <img
                            src={`https://img.youtube.com/vi/${extractVideoId(guidance.video)}/mqdefault.jpg`}
                            className="img-fluid"
                            width="1000px"
                        />
                    </span>
                    <span className="video_block_name">
                        <label>{guidance.title}</label>
                        <span onClick={() => handleShowGuidanceVideo(guidance.video)} className="icon-invert-play" />
                    </span>
                </a>
            </div>
        </div>
    );
};

export default GuidanceCardControl;
