import { Modal } from 'bootstrap';
import { isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import StudentService from '../../../../services/student.service';
import { SmartContext } from '../../../../library/Core/SmartContext';
import axios from 'axios';

export const handleSubmit = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
        event.preventDefault();
        const isValidSchool = isValidateSchool(state, dispatch);
        const customValidationPassed = validateMarksByCurriculum(state, dispatch);

        if (customValidationPassed || isValidSchool ) {
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }
    
    // Send event to Moengage my schooling
    sendMoEngageEvent(state?.data);
        
        StudentService.pageSave(
            state.routeInfo.id,
            state.routeInfo.pageName,
            StudentService.toStudentEntity({
                id: state.routeInfo.id,
                schools: state?.data['schools'],
               // schoolCurrent: state?.data['schoolCurrent'],
               // schoolClass10: state?.data['schoolClass10'],
               // schoolClass12: state?.data['schoolClass12'],
            })
        ).then(() => {
            state.actions['ROUTER_NAVIGATE'](`/alumni/${state.routeInfo.id}/profile/education`);
        });
 };

 const isValidateSchool = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const schoolsData = state?.data?.schools;
    console.log(schoolsData);
    if (schoolsData && Array.isArray(schoolsData)) {
        let errorFound = false; // Track if any error is found
        let encounteredClassCodes = new Set(); 
        schoolsData.forEach((schoolsData, index) => {
            if (isEmpty(schoolsData?.endYear)) {
                setError(`schools.${index}.endYear`, ['Please enter "Completion Year"'], dispatch);
                errorFound = true; 
            }

            if (isEmpty(schoolsData?.classCode)) {
                setError(`schools.${index}.classCode`, ['Please enter "Attended Grade"'], dispatch);
                errorFound = true; 
            }else if (encounteredClassCodes.has(schoolsData.classCode)) {
                setError(`schools.${index}.classCode`, [`Attended Grade '${schoolsData.classCode}' is already entered`], dispatch);
                errorFound = true;
            } else {
                encounteredClassCodes.add(schoolsData.classCode);
            }

            if (isEmpty(schoolsData?.curriculumCode)) {
                setError(`schools.${index}.curriculumCode`, ['Please enter "Your Curriculum"'], dispatch);
                errorFound = true; 
            }
            if (isEmpty(schoolsData?.schoolId) && isEmpty(schoolsData?.class10SchoolName)) {
                setError(`schools.${index}.schoolId`, ["School name is required"], dispatch);
                errorFound = true; 
            }

        });

        return errorFound;
     
    } else {
        console.error("Admin accounts data is missing or invalid");
    }
  }

  const validateMarksByCurriculum = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const schoolsData = state?.data?.schools;

    if (!schoolsData || !Array.isArray(schoolsData)) {
        console.error("Schools data is missing or invalid");
        return true; 
    }

    let errorFound = false; 

    schoolsData.forEach((school: any, index: any) => {
        setError(`schools.${index}.marks`, [], dispatch);

        if(school?.curriculumCode != 2){
            const marks = school?.marks;
            if (isNaN(marks)) { // Check if marks is not an integer here marks comes in string 
                setError(`schools.${index}.marks`, [`Marks must be an integer value`], dispatch);
                errorFound = true;
                return; 
            }
        }
    });


    schoolsData.forEach((school, index) => {
        const marks = school?.marks;
        let min, max;

        setError(`schools.${index}.cieGrade`, [], dispatch);

        switch (school?.curriculumCode) {
            case '2': 
                const cieGrade  = school?.cieGrade;
                let sum: number = Object.values(cieGrade).reduce((acc: number, currentValue) => acc + Number(currentValue), 0);
                min = school?.classCode == 10 ? 5 : 3;
                if ( sum < min ) {
                    setError(`schools.${index}.cieGrade`, [`Total should sum to ${min} or higher in any combination from the dropdowns.`], dispatch);
                    errorFound = true;
                } else {
                    setError(`schools.${index}.cieGrade`, [], dispatch);
                    // errorFound remains false here because it's already true if sum < min
                }
                break;

            case '5':
                min = 24; max = 45;
                if (marks < min || marks > max) {
                    setError(`schools.${index}.marks`, [`Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}`], dispatch);
                    errorFound = true;
                }
                break;

            case '1': 
                min = 1; max = 10;
                if (marks < min || marks > max) {
                    setError(`schools.${index}.marks`, [`Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}`], dispatch);
                    errorFound = true;
                }
                break;

            default: // Default validation
                min = 1; max = 100;
                if (marks < min || marks > max) {
                    setError(`schools.${index}.marks`, [`Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}`], dispatch);
                    errorFound = true;
                }
                break;
        }
        console.log("errorFound", errorFound);

    });

    
    return errorFound; // Return true if any error was found, indicating validation failure
};


export const onSubjectWiseMarksChange = (props: ControlOnChangeArguments, dispatch: DispatchEvent) => {
    console.log(props.parentDataKey)
     if(props?.value){
        props.dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showsubjectmarksModal' } });
        props.dispatch({ type: 'SET_MODAL_DATA', payload: { title: props.row.classCode, data:props.row, parentDataKey : props.parentDataKey } });
     }

    //  const modalElement = document.getElementById('update-subject-marks');
    //  if (modalElement) {
    //      const modal = new Modal(modalElement);
    //      modal.show();
    //  }
 };
   


/**
 * @func validating marks by curriculum
 * @param {number} classCode class of study
 * @param {State} state state data
 * @param {DispatchEvent} dispatch dispatch event 
 * @returns {boolean} isFormValid form valid flag
 * 
 * */

// const validateMarksByCurriculum = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
//     const marks = state?.data?.[`schoolClass${classCode}`]?.marks;
//     let min, max;
//     let message: any = null;
//     let field = 'marks';
//     let isFormValid = true;

//     switch (state?.data?.[`schoolClass${classCode}`]?.curriculumCode) {
//         case '5': // CIE
//             const cieGrade  = state?.data?.[`schoolClass${classCode}`]?.cieGrade;
//             let sum: number = Object.values(cieGrade).reduce((acc: number, currentValue) => acc + Number(currentValue), 0);
//             min = classCode == 10 ? 5 : 3;
//             field = 'cieGrade';
//             if ( sum < min ) {
//                 isFormValid = false;
//                 message = `Total should sum to ${min} or higher in any combination from the dropdowns.`;
//             }
//             break;

//         case '6': // IB
//             min = 24; max = 45;
//             if ( marks < min || marks > max ) {
//                 isFormValid = false;
//                 message = `Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}.`;
//             }
//             break;

//         case '8': // American Curriculum
//             min = 1; max = 10;
//             if ( marks < min || marks > max ) {
//                 isFormValid = false;
//                 message = `Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}.`;
//             }
//             break;
        
//         default: // Remaining all curriculum
//             min = 35; max = 100;
//             if ( marks < min || marks > max ) {
//                 isFormValid = false;
//                 message = `Please ensure that the marks provided fall within the acceptable range of ${min} to ${max}.`;
//             }
//             break;
//     }

//     if ( ! isFormValid ) {
//         setError(`schoolClass${classCode}.${field}`, [message], dispatch);
//     } else {
//         setError(`schoolClass${classCode}.${field}`, [], dispatch);
//     }

//     return isFormValid;
// }



// following code be removed
// export const isFormValidBasedOnCustomValidation = (state: State, dispatch: any) => {
//     let isFormValid = true;

//     if (state?.data?.schoolClass10?.curriculumCode === '5') {
//         const cieGrade = state?.data?.schoolClass10?.cieGrade;

//         if (cieGrade?.AStar || cieGrade?.A || cieGrade?.B || cieGrade?.C || cieGrade?.D || cieGrade?.E) {
//             isFormValid = false;
//             setError(`schoolClass10.cieGrade`, ['Please enter all the grades - A* -> G'], dispatch);
//         }
//     }

//     return isFormValid;
// };

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-school-details`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };