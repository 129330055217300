import AchievementsVisual from '../../../../styles/images/achievements_visual.svg';
// import SchoolLogo from '../../../../styles/images/image-20.png';
import Trophy from '../../../../styles/images/tropy.png';
import AchievementCarouselControl from './AchievementCarouselControl';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import PopUpImg from '../../../../styles/images/pop-img.png';
import { useEffect, useState, useContext } from 'react';
import SessionContext from '../../../../library/Core/SessionContext';

var stepMessage: any = sessionStorage.getItem('step-message');

const AchievementLayout = (props: any) => {
    const { sessionState } = useContext(SessionContext);
    const userStatusCode = sessionState?.userStatusCode;
    const userTypeCode = sessionState?.userTypeCode;
    const [profilePercentage, setprofilePercentage] = useState<any>('');
    //const [userStatusCode, setUserStatusCode] = useState(sessionStorage.getItem('user-status-code'));
    let SchoolLogo = sessionStorage.getItem('school-image') as any;

    useEffect(() => {
        const profilePercentage = sessionStorage.getItem('profile-percentage') ? sessionStorage.getItem('profile-percentage') : 0;
        setprofilePercentage(profilePercentage);
    }, [sessionStorage.getItem('profile-percentage')]);

    // useEffect(() => {
    //     setUserStatusCode(sessionStorage.getItem('user-status-code'));
    // }, []);

    const navigate = useNavigate();
    return (
        <>
            {userTypeCode != 'SUPER_ADMIN' && userStatusCode != 'ACTIVE' && (
                <header className="ful-header">
                    <div className="header_container">
                        <a href="">
                            <img src={SchoolLogo} alt="" />
                        </a>

                        <div className="global_wrap">
                            {(profilePercentage != 0 || (stepMessage !== null && stepMessage !== '')) && (
                                <div className="global bg_transparant show" data-pai="75">
                                    {stepMessage !== null && stepMessage !== '' && (
                                        <div className={profilePercentage != 0 ? 'unlock green_recom_bg_left' : 'unlock green_recom_bg'}>
                                            <div className="img">
                                                <img src={Trophy} alt="" />
                                            </div>
                                            <div className="text">{stepMessage}</div>
                                        </div>
                                    )}
                                    {profilePercentage != 0 && (
                                        <div className="progress_svg">
                                            <CircularProgressbar
                                                value={profilePercentage}
                                                text={`${profilePercentage}%`}
                                                styles={buildStyles({
                                                    // This is in units relative to the 100x100px
                                                    // SVG viewbox.
                                                    textSize: '25px',
                                                    //   pathColor: `rgba(62, 152, 199, ${profilePercentage / 100})`,
                                                    textColor: '#000',
                                                    //   trailColor: '#d6d6d6',
                                                })}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <label className="user_name">
                            Hey <span>{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                        </label>
                    </div>
                </header>
            )}
            <div className="main-wrap">
                <section className="scholarship_banner d-none d-md-block aos-init aos-animate" data-aos="fade-down">
                    <div className="scholarship_container">
                        <div className="row g-4">
                            <div className="col-md">
                                <h2>
                                    Your <span>Achievements</span> Matter{' '}
                                </h2>
                                <ul className="scholarship_detail_list">
                                    <li>Adding your Achievements increases your Profile Views and Visits by 10X!</li>
                                </ul>
                            </div>
                            <div className="col-md-auto text-center d-flex align-items-end justify-content-center pt-0 pt-md-5">
                                <img src={AchievementsVisual} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="step_header_wrap bg-pink-gradient">
                    <a
                        data-bs-toggle="collapse"
                        data-bs-target="#scholarship_detail_list"
                        href=""
                        className="mobile-step-block"
                        aria-expanded="false"></a>

                    <div className="step_header_wrap_title">
                        <h2>SCHOLARSHIP DETAILS AND ITS IMPORTANCE -</h2>
                        <div className="listed_points collapse" id="scholarship_detail_list">
                            <ul className="scholarship_detail_list pink-line">
                                <li>Enriches Your Profile</li>
                                <li>Offer Scholarship Guidance to Students</li>
                                <li>Conduct Webinars as a Mentor</li>
                            </ul>
                        </div>
                        <a href="" className="step_header_wrap_link">
                            Read <span className="more">More</span>
                            <span className="less">Less</span>
                        </a>
                    </div>
                    <div className="step_header_wrap_img">
                        <img src="images/achievements_visual.svg" className="img-fluid float_horizontal_animate" width="100%" alt="" />
                    </div>
                </div>
                <a
                    data-bs-toggle="collapse"
                    data-bs-target="#scholarship_detail_list"
                    href=""
                    className="bg-drop"
                    aria-expanded="false"></a>
                <section className="scholarship_detail_carousel">
                    <div className="scholarship_detail_carousel_container">
                        <div className="row g-4">
                            <div className="col-lg aos-init aos-animate" data-aos="fade-right" data-nextpage="skill.html">
                                <form className="row g-3 needs-validation was-validated">
                                    <div className="steps showTab" date-bgclr="basic-information" id="step1">
                                        <div className="steps_questions">{props.children}</div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-auto aos-init aos-animate" data-aos="fade-left">
                                <div className="scholarship_block">
                                    <h3>
                                        TAKE A LOOK AT SOME <span>Alumni Achievements</span>
                                    </h3>
                                    <AchievementCarouselControl />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade show" id="skip_pop" aria-labelledby="skip_popLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="brife">
                                <h4>ACHIEVED IT? FLAUNT IT!</h4>
                                <p>Make your profile unique, and inspire students to achieve more!</p>
                            </div>
                            <div className="img_pop">
                                <img src={PopUpImg} alt="" />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                className="btn btn-goback"
                                data-bs-target="#exampleModalToggle"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:none');
                                }}>
                                GO BACK
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    sessionStorage.setItem('user-status-code', 'ACTIVE');
                                    document.getElementById('updateUserStatusCode')?.click();
                                    navigate('/alumni/onboarding');
                                }}
                                className="btn btn-later">
                                I'LL DO THIS LATER
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AchievementLayout;
