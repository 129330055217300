import React, { useContext,useState, useEffect, useRef  } from 'react';
import { SimpleFormControlArguments, State } from '../../../library/Core/SmartTypes';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import axios from 'axios';
const PreviewLater =  (args: SimpleFormControlArguments) => {
  const urlParams = new URLSearchParams(window.location.search);
  const templateId = urlParams.get("template_id");
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [sender, setSender] = useState<string>('');
  const [audience, SetAudience] = useState<string>('');
  const [schoolImage, setSchoolImage] = useState<string>('');
  const [attached_file_path, setAttachedFilePath] = useState<string>(''); // Define attached_file_path state
  let sesinstituteId=0;
  const userDataString = sessionStorage.getItem('user');
  if (userDataString) {
      // Parse JSON string to object
      const userData = JSON.parse(userDataString);
      sesinstituteId = userData.primaryInstitute; 
            
  }
  const schoolId =sesinstituteId;

  //const school_id = sessionStorage.getItem('user-school-id');

  //const school_name = getDomainValueForCode(school_id, domainCategoryCode, state);
  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/get-template`, {
        id:templateId
      });
      // alert(response.data['subject']);
      // alert(response.data['message'])

      // Extract the message from the response
      const messageFromAPI = response.data['message'];
      const subjectFromAPI = response.data['subject'];
      const senderFromAPI = response.data['sender'];
      const audienceFromAPI = response.data['audience_count'];
      const attachedFilePathFromAPI = response.data['attached_file_path']; // Extract attached_file_path
      const schoolImage =response.data['university_logo'];
            
      //alert(attachedFilePathFromAPI);
      setMessage(messageFromAPI);
      setSubject(subjectFromAPI);
      setSender(senderFromAPI);   
      SetAudience(audienceFromAPI);      
      setAttachedFilePath(attachedFilePathFromAPI); 
      setSchoolImage(schoolImage);
            
      // Rest of your code

    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const SendLaterData = async (day: string, month: string, year: string, hour: string, minut: string,abbreviations:string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_COMMON_API}/email/send-later`, {
        teplateid: templateId,
        schedule_for_later: `${year}-${month}-${day} ${hour}:${minut} ${abbreviations}` // Construct the scheduled datetime
      });
      // setSuccessMessage('Email scheduled successfully');
      // setErrorMessage(''); // Reset any previous error 
      window.location.href = `/broadcast/schedule-mail`;
      // Handle the response or any errors as needed.
    } catch (error) {
      console.error(error);
    }
  };
let numberOfStudents:number=0;


  useEffect(() => {
    fetchData(); // Call the async function when the component mounts
  }, []); // Empty dependency array to run once on mount
  
  const [isSendNowSelected, setIsSendNowSelected] = useState(true);
 
  const shouldRenderAttachedDiv = attached_file_path !== "";


    // Define state variables to manage which button is clicked
    const [isSendClicked, setIsSendClicked] = useState(false);
    const [isScheduleClicked, setIsScheduleClicked] = useState(false);
  
    // Handle the "Send" button click
  
  
    // Handle the "Schedule" button click
    const handleScheduleClick = () => {
      setIsSendClicked(false); // Ensure "Send" is not clicked
      setIsScheduleClicked(true);
    
    
      // Extract the day, month, year, hour, and minute from the select elements
      const day = (document.getElementById('day') as HTMLSelectElement).value;
      const month = (document.getElementById('month') as HTMLSelectElement).value;
      const year = (document.getElementById('year') as HTMLSelectElement).value;
      const hour = (document.getElementById('hour') as HTMLSelectElement).value;
      const minut = (document.getElementById('minut') as HTMLSelectElement).value;
      const abbreviations = (document.getElementById('abbreviations') as HTMLSelectElement).value;
    
      // You can also get the AM/PM value similarly
    
      SendLaterData(day, month, year, hour, minut,abbreviations);
    };
  
    // Function to make the API request based on which button is clicked
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    return (
      
<div className="main flex-1">
      <div className="max-1140 mx-auto d-flex h-100">
        <div className="flex-1 d-flex flex-column mb-5">
          <div className="pageContent flex-1 ps-0">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="white-block white-block-notopborderradius h-100 p-0">
                  <div className="borderBottom border-2 custom-border-gray p-4 pb-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                      <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title aos-init aos-animate">Preview</h3>
                    </div>
                  </div>
                  <div className="row sd_formsec">
                    <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pb-4 px-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="900" id="no-notification">
                      <form className="lstCustForm w-100 formrowpx mt-2 px-4 px-md-0">
                        <div className="row g-4">
                         
                          <div className="col-12">
                            <div className="row">
                              <div className="col-sm-4"><span className="font-16 font-500">Title of the Broadcast</span></div>
                          <div className="col-sm-8" id="div_subject" > {subject} </div>                         
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-sm-4"><span className="font-16 font-500">Sender Name</span></div>
                              <div className="col-sm-8">{sender}</div>                           
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-sm-4"><span className="font-16 font-500">Audience</span></div>
                              <div className="col-sm-8" id="noofstudent">{audience}   Profiles</div>                         
                            </div>
                          </div>
                          <div className="col-12">
                            <hr />
                          </div>
                          <div className="col-12 text-center">
                            <span className="font-18 font-500">Email Preview</span>
                          </div>
                          <div className="col-12">
                            <div className="border border-1 border-radius-6 preview-section">
                              <div className="preview-header">
                                <div className="logo_preview d-none"><img src="images/preview_logo.jpg" width="150" className="img-fluid" alt="" /></div>
                                <div className="univarsity_logo"><img src="images/dark-logo.svg" width="150" className="img-fluid" alt="" /></div>
                              </div>
                              {/* dangerouslySetInnerHTML={{ __html: message }} */}
                              <div className="preview-body" id="preview-body" >
                              <table  className="nl-container" style={{background:'#e8e8e8', width:'100%',border:'0'}} >
            <tbody>
                <tr>
                    <td>
                        <table align="center"  className="row row-1" style={{width:'100%',border:'0'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table align="center" className="row-content stack" style={{borderRadius:'0',width:'600px',border:'0',color:'#000'}} >
                                            <tbody>
                                                <tr>
                                                    <td className="column column-1" style={{fontWeight:'400', textAlign:'left', verticalAlign:'top', paddingTop:'10px', paddingBottom:'10px',borderTop:'0',borderRight:'0',borderBottom:'0',borderLeft:'0',width:'100%' }} >
                                                        <table  className="empty_block block-1" style={{width:'100%',border:'0'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad">
                                                                        <div>
                                                                            <br/>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table align="center"  className="row row-2" style={{width:'100%', margin: 'auto'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table align="center"  className="row-content stack" style={{width:'600px',color:'#000'}} >
                                            <tbody>
                                                <tr>
                                                    <td className="column column-1"  style={{fontWeight:'400',width:'100%',textAlign:'left',color:'#000', backgroundColor:'#fff', paddingLeft:'10px', paddingRight:'10px', verticalAlign:'top', paddingTop:'10px',paddingBottom:'0', borderTop:'0',borderRight:'0',borderBottom:'0',borderLeft:'0'}} >
                                                        <table  className="image_block block-1" style={{width:'100%'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad" style={{paddingBottom:'15px',paddingTop:'20px', width:'100%', paddingRight:'0', paddingLeft:'0'}}>
                                                                        <div  className="alignment" style={{lineHeight:'10px'}} > {schoolImage && (
                                                                                                                                        <img
                                                                                                                                            src={
                                                                                                                                                schoolImage
                                                                                                                                            }
                                                                                                                                            style={{
                                                                                                                                                display:
                                                                                                                                                    'block',
                                                                                                                                                height: 'auto',
                                                                                                                                                border: '0',
                                                                                                                                                width: '136px',
                                                                                                                                                maxWidth:
                                                                                                                                                    '100%',
                                                                                                                                                margin: 'auto',
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                    )}</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table  className="image_block block-2" style={{width:'100%'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad" style={{width:'100%', paddingRight:'0', paddingLeft:'0'}} >
                                                                        <div className="alignment" style={{lineHeight:'10px'}}><img className="big" src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/MA9APFJAMODXI6YT3IKC239C/podar_signup_images/group-81169.png" style= {{display:'block', height:'auto', border:'0', width:'520px', maxWidth:'100%'}} /></div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table align="center"  className="row row-3" style={{width:'100%', margin: 'auto'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table align="center"  className="row-content stack" style={{color:'#333',width:'600px'}}>
                                            <tbody>
                                                <tr>
                                                    <td className="column column-1" style={{fontWeight:'400', textAlign:'left', width:'100%', backgroundColor:'#fff', verticalAlign:'top', borderTop:'0',borderRight:'0', borderBottom:'0',borderLeft:'0' }} width="100%">
                                                    
                                                        <table  className="text_block block-2 mobile_hide" style={{wordBreak:'break-word', width:'100%'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad" style={{paddingBottom:'15px',paddingLeft:'60px',paddingRight:'60px',paddingTop:'15px'}}>
                                                                        <div style={{fontFamily:'sans-serif'}}>
                                                                          
                                                                            <div style={{fontSize:'12px',color:'#000', lineHeight:'1.2',fontFamily:'Arial,Helvetica Neue,Helvetica,sans-serif'}} >
                                                                            <div className="preview-body" id="preview-body" dangerouslySetInnerHTML={{ __html: message }}></div>
                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="row row-4 mobile_hide" style={{width:'100%' , margin: 'auto'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table className="row-content stack" style={{borderRadius:'0',color:'#000',width:'600px'}} >
                                            <tbody>
                                                <tr>
                                                    <td className="column column-1" style={{width:'100%',fontWeight:'400',textAlign:'left',backgroundColor:'#fff',verticalAlign:'top', paddingTop:'5px', paddingBottom:'5px',borderTop:'0',borderRight:'0',borderBottom:'0',borderLeft:'0'}} >
                                                     
                                                        <table  className="html_block block-3 desktop_hide" style={{display:'none',maxHeight:'0',overflow:'hidden', width:'100%'}}  >
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad">
                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="spacer_block" style={{height: '5px', lineHeight: '5px', fontSize: '1px'}}></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table align="center"  className="row row-10 mobile_hide" style={{width:'100%' , margin: 'auto'}} >
                            <tbody>
                                <tr>
                                    <td>
                                        <table align="center"  className="row-content stack" style={{color:'#000',borderRadius:'0',width:'600px'}} >
                                            <tbody>
                                                <tr>
                                                    <td className="column column-1" style={{fontWeight:'400',textAlign:'left',backgroundColor:'#fff',verticalAlign:'top',paddingTop:'5px',paddingBottom:'5px',borderTop:'0',borderRight:'0',borderBottom:'0',borderLeft:'0', width:'100%'}}>
                                                  
                                                        <table  className="divider_block block-2" style={{width:'100%', border:'0'}} >
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad">
                                                                        <div  className="alignment">
                                                                            <table  style={{width:'89%', margin: 'auto'}} >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="divider_inner" style={{fontSize:'1px',lineHeight:'1px',borderTop:'1px solid #bbb'}}><span> </span></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                      
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table align="center"  className="row row-11" style={{width:'100%'}}  >
                            <tbody>
                                <tr>
                                    <td>
                                        <table align="center"  className="row-content stack" style={{color:'#000', borderRadius:'0', width:'600px'}} >
                                            <tbody>
                                                <tr>
                                                    <td className="column column-1" style={{fontWeight:'400',textAlign:'left',verticalAlign:'top',paddingTop:'5px',paddingBottom:'5px',borderTop:'0',borderRight:'0',borderBottom:'0',borderLeft:'0', width:'100%'}}>
                                                        <table  className="empty_block block-1" style={{width:'100%'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="pad">
                                                                        <div style={{textAlign: 'center', paddingBottom: '20px', fontSize: '10px', lineHeight: '24px', fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif'}}>
                                                                        <div style={{ display: 'block', margin: '15px 0 0', textAlign: 'center' }}>
      <a href="https://www.facebook.com/univariety/" target="_blank" style={{ display: 'inline-block', margin: '3px 5px' }}>
        <img src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/KT8GXXMDUE4J01BJKQORHEC4/icons/facebook_icon.png" alt="" />
      </a>
      <a href="https://www.linkedin.com/company/univariety/" target="_blank" style={{ display: 'inline-block', margin: '3px 5px' }}>
        <img src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/KT8GXXMDUE4J01BJKQORHEC4/icons/linkedin_icon.png" alt="" />
      </a>
      <a href="https://www.instagram.com/theofficialunivariety/" target="_blank" style={{ display: 'inline-block', margin: '3px 5px' }}>
        <img src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/KT8GXXMDUE4J01BJKQORHEC4/icons/instagram_icon.png" alt="" />
      </a>
      <a href="https://twitter.com/Univariety" target="_blank" style={{ display: 'inline-block', margin: '3px 5px' }}>
        <img src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/KT8GXXMDUE4J01BJKQORHEC4/icons/twitter_icon.png" alt="" />
      </a>
      <a href="https://www.youtube.com/channel/UCpwpzaFC6Dn3xDLrlEiFoUA?view_as=subscriber" target="_blank" style={{ display: 'inline-block', margin: '3px 5px' }}>
        <img className="m-0" src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/f7683d20-2948-460f-8f91-a13fe519b593/KT8GXXMDUE4J01BJKQORHEC4/icons/youtube_icon.png" alt="" />
      </a>
    </div>
                                                                            <p style={{lineHeight: '18px', margin: '8px 0 0'}}> This email was sent by <a href="mailto:announcements@univariety.com">announcements@univariety.com</a> on behalf of University
                                                                                <br/> If you’d like us to refrain from sending you similar e-mails in the future,
                                                                                <br/>please click on the unsubscribe link below OR
                                                                                 send a written request to Univariety at:
                                                                                <br/>H. No. 8- 2-624, Suite 301, Sri Durga Towers, Road No. 10,
                                                                                <br/>Banjara Hills, Hyderabad, Telangana - 500034 (India)</p>
                                                                            © Univariety | <a href="https://www.univariety.com/app/home/privacy-policy">Data Privacy Policy</a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
                              </div>
                            </div>
                          </div>
                          {shouldRenderAttachedDiv && (
                          <div className="col-12" id="attached">
                            <div className="row g-3 align-items-center">
                              <div className="col-md-auto">
                                <label htmlFor="AudienceCategory" className="form-label m-0 mb-1 font-16 font-500 w-100">Attachments</label>                            
                              </div>
                              <div className="col-md">
                              <a href={attached_file_path} className="btn btn-border-gray position-relative" target='_blank'>
                                 <i className="icon-pdf me-1"></i><span>Preview File</span></a>
                              </div>
                            </div>
                          </div>
                          )}
                        
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       

        </div>
      </div>
    </div>
   );
};
export default PreviewLater;
